import React from 'react'
import { graphql } from 'gatsby'
import PhotoList from '../components/PhotoList'

import Layout from '../components/layout'
import SEO from '../components/SEO'

const IndexPage = ({ data: { files: { totalCount, photos = [] } } }) => (
  <Layout>
    <PhotoList photos={photos}/>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    files: allFile(
      filter: {
        extension: { in: ["jpeg", "jpg"] }
        dir: { regex: "/photos/" }
      },
      sort: {fields: [birthTime], order: ASC}
    ) {
    totalCount
     photos: edges {
       photo: node {
         id
         relativePath
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
         fields {
          tags
          iptc {
            date_time
          }
          latitude
          longitude
          location
        }
       }
     }
    }
  }
`
